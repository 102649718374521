import React from 'react';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import {
    Button, IconButton, Card, CardHeader, CardContent, Typography,
    ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Tooltip,
    Menu, MenuItem, InputLabel
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import immutable from 'seamless-immutable';
import ActivitiesMain from './ActivitiesMain';
import { withStyles } from '@material-ui/core/styles';
import {
    getActivities, setInitialState, getById, getWODropdown,
    deleteActivity, getStatusDropdown
} from '../../actions/activity/ActivityActions';
import { getFieldDropDown } from '../../actions/catastral_data/FieldActions';
import { Delete } from '../../utils/Delete';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import ReactSelect from '../../utils/ReactSelect';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import iconStyle from './activities_new/status.module.css';
import axios from './../../utils/AxiosWrapper';
import { Route, Redirect } from 'react-router-dom';
import { wentWrong } from '../../utils/ToastConfig';
import ReactPaginate from "react-paginate";
import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';
import { Export } from '../../utils/Export';

var FileSaver = require('file-saver');
var XLSX = require('xlsx');

const STATUS_CLOSED = 1

const styles = theme => ({
    page: {
        width: '96%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
        height: '100%'
    },
    card: {
        width: '100%',
        boxShadow: '0 2px 4px rgba(154,160,185,0.05),  0 4px 8px rgba(166,173,201,0.2)',
        borderRadius: '8px'
    },
    cardHeader: {
        padding: "0.75rem 1.25rem",
        margin: "15px 15px -15px 15px",
        background: theme.palette.secondary.light,
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor: '#DCDCDC',
        padding: '8px 0px 8px 20px'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    cardAction: {
        marginRight: 20
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    actionButton: {
        width: 50,
        height: 50,
        flexFlow: 'row wrap',
        alignItems: 'center'
    },
    search: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '40%',
        height: 40,
        borderRadius: '25px',
    },
    typography: {
        color: '#fff'
    },
    filterLabel: {
        margin: '10px',
        color: theme.palette.primary.main
    },
    activityDetail: {
        fontWeight: 400,
        lineHeight: 1.3
    },
    activityId: {
        backgroundColor: '#DCDCDC',
        width: '75px',
        display: 'flex',
        justifyContent: 'center'
    },
    // -------------------------------
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px'
    },
    searchBar: {
        width: '40%',
        height: '40px !important',
        border: '1px solid #979797',
        maxWidth: '421px'
    },
    addNewBtn: {
        width: 200,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD'
        }
    },
    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    // Date Select Button within Tooltip
   
    dateSelectBtn: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        marginRight: '20px',
        cursor: 'pointer',
        padding: '1px 8px',
        paddingLeft: '5px',
        paddingrRight: '10px',
        borderRadius: '4px',
        display: 'flex',              // Ensures items inside are aligned properly
        alignItems: 'center',         // Align items vertically
        justifyContent: 'flex-start',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
        borderBottom: '1px solid #333333', // Add underline similar to dropdown
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px #333333',
        },
        '&:hover': {
            backgroundColor: '#E2E5E7',
            borderBottomColor: '#777777',  // Slightly lighter border on hover
        }
    },
    
    // Text in the Date Select Button (No Underline here)
    dateSelectText: {
        fontSize: '14px',
        color: '#333333',
        padding: '0px',
        paddingLeft: '10px',
        textDecoration: 'none',  // Remove underline from text
    },
    
    calendar: {
        width: '500px',
        padding: '20px 50px 0 30px',
    },

    calendarTitle: {
        color: theme.palette.primary.main,  // Use primary color from theme
        fontSize: '20px',
        textAlign: 'center',
        display: 'flex',  // Use flexbox to align the icon and text
        alignItems: 'center',  // Vertically align text and icon
        justifyContent: 'center',  // Center both horizontally
        marginTop: '10px',  // Space between popup and title
    },
    
    // Close Button inside Calendar
    calendarCloseBtn: {
        display: 'block',
        margin: '10px auto',
        backgroundColor: '#F4F6F7',
        color: '#333333',
        border: 'none',
        padding: '5px 12px',
        cursor: 'pointer',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#E2E5E7',
        },
    },
    
    // Dropdown Styles
    dropDowns: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        marginRight: '20px',
        cursor: 'pointer',
        padding: '5px 12px',          // Same padding as dateSelectBtn for alignment
        borderRadius: '4px',
        display: 'flex',              // Ensures items inside are aligned properly
        alignItems: 'center',         // Align items vertically
        justifyContent: 'flex-start', // Align dropdown items to the left
        borderBottom: '1px solid #333333', // Underline
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px #333333',
        },
        '&:hover': {
            backgroundColor: '#E2E5E7',
        },
        '& option': {
            backgroundColor: '#FFFFFF',
            color: '#333333',
            fontSize: '14px',
        },
    },
    
    

    resetButton: {
        background: 'linear-gradient(90deg, #E6E9EC, #D1D6D8)', // Lighter gray gradient
        color: '#333333',
        fontSize: '14px',
        fontWeight: 'normal',
        border: 'none',
        padding: '8px 12px',
        borderRadius: '4px', // Matches dropdown shape
        cursor: 'pointer',
        display: 'flex',              // Ensures items inside are aligned properly
        alignItems: 'center',         // Align items vertically
        justifyContent: 'flex-start',
        transition: 'background 0.3s ease', // Smooth hover transition
        borderBottom: '1px solid #333333', // Underline like dropdown
        '&:hover': {
            background: 'linear-gradient(90deg, #E2E5E7, #D5D8DA)', // Matching hover gradient with a darker tone
        },
        '&:active': {
            background: 'linear-gradient(90deg, #D5D8DA, #C7CACD)', // Slightly darker gradient for active state
        },
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 0 2px #333333', // Optional: subtle focus indicator
        },
    },
    
    tableHeader: {
        height: '40px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#1F764E',
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
    },
    tableHeaderTitle: {
        fontSize: '16px',
        color: 'white',
    },
    selectSeasonSection: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        '& > label': {
            color: 'white',
            margin: '0 10px',
        },
        '& > select': {
            cursor: 'pointer',
            color: 'white',
            backgroundColor: '#1F764E',
            border: 'none',
            borderBottom: '1px solid white',
            '&:focus': {
                outline: 'none',
            },
        },
    },
    paginationContainer: {
        marginTop: 'auto',
        marginBottom: 0,
        listStyle: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        userSelect: 'none',
        backgroundColor: 'white',
        padding: 0,
        color: '#7d7a7a',
        '& > li': {
            margin: '20px',
        },
        '& > .previous': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > .next': {
            fontSize: '20px',
            padding: '5px',
            fontWeight: 'bold',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '& > li > a': {
            cursor: 'pointer',
            color: '#7d7a7a',
        }
    },
    active: {
        border: '2px solid #7d7a7a',
        borderRadius: '50%',
        padding: '5px',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    exportButton: {
        width: '30px !important',
        height: '30px !important',
        minHeight: '30px !important',
        fontSize: '24px !important',
        marginLeft: 'auto !important',
        marginRight: '30px',
    },

});
export class ActivityContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            delete: false,
            name: '',
            edit: false,
            is_copy: false,
            searchValue: '',
            forSortValue: 'by field',
            anchorEl: null,
            new_edit: false,
            new_copy: false,
            propObj: {},
            filters: {
                date: {
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                }
            },
            // -----------------------------
            filteredActivities: [],
            selectedSeason: "",
            selectedTable: 0,
            selectedStatus: "",
            selectedOperation: "",
            anchorDatePicker: null,
            selectedDateRange: {
                endDate: null,
                selection: {
                    startDate: null,
                    endDate: null,
                    key: 'selection',
                },
                startDate: null,
            },
            searchBarValue: "",
            currentPage: 0, // index based
            pageCount: 1,
            itemsPerPage: 8,
            exportWindow: false,
            docTitle: null,
        };
    }

    componentDidMount() {
        const { clientProfile } = this.props;
        let { filters } = this.state;
        filters.id_sezona = clientProfile.id_default_sezona || null;
        this.setState({
            filters
        })
        this.props.getWODropdown();
        this.props.getFieldDropDown();
        this.props.getStatusDropdown();
        if (filters.id_sezona !== null) {
            this.props.getActivities(this.state.searchValue, filters);
        }

        axios.get('api/activity/sort_client', { params: { status: this.state.forSortValue } })
            .then(res => {
                if (res.data.data[0]) {
                    if (res.data.data[0].sort) {
                        this.setState({ forSortValue: res.data.data[0].sort })

                    }
                }
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))

        const storedNumber = sessionStorage.getItem('pageNumber');
        if (storedNumber) {
            this.setState({ currentPage: Number(storedNumber) });
        } else {
            this.setState({ currentPage: 0 });
        }

        this.setupPageCount();
    }

    componentDidUpdate(prevProps, prevState) {
        const { activity, fieldDropDown, woDropdown, statusDropdown, activityPostingSuccess, clientProfile, getActivities, deleteActivitySuccess } = this.props;
        let { filters } = this.state;
        if (prevProps.deleteActivitySuccess === false && deleteActivitySuccess === true) {
            this.setState({
                searchValue: ''
            })
            this.props.setInitialState('deleteActivitySuccess');
        }
        if (prevProps.activity !== activity) {
            this.setState({
                activity: activity
            }, () => {
                this.sortByProperty({ val: this.state.forSortValue })
                this.populateDropdowns();
            });
        }
        if (prevProps.fieldDropDown !== fieldDropDown) {
            this.setState({
                fieldDropDown: immutable.asMutable(fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.woDropdown !== woDropdown) {
            this.setState({
                woDropdown: immutable.asMutable(woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.statusDropdown !== statusDropdown) {
            this.setState({
                statusDropdown: immutable.asMutable(statusDropdown.map((item) => { return { label: i18n.language === 'Srpski' ? item.naziv : item.naziv_en, value: item.id } }), { deep: true }),
            })
        }
        if (prevProps.activityPostingSuccess === false && activityPostingSuccess === true) {
            this.setState({
                add: false,
                searchValue: ''
            })
            this.props.setInitialState('activityPostingSuccess');
        }
        if (prevProps.clientProfile !== clientProfile) {
            filters.id_sezona = clientProfile.id_default_sezona;
            this.setState({
                filters
            })
            getActivities(this.state.searchValue, filters)
        }
        // -----------------------------------------------
        if (prevState.selectedSeason !== this.state.selectedSeason ||
            prevState.selectedTable != this.state.selectedTable ||
            prevState.selectedStatus !== this.state.selectedStatus ||
            prevState.selectedOperation !== this.state.selectedOperation ||
            prevState.selectedDateRange !== this.state.selectedDateRange ||
            prevState.searchBarValue !== this.state.searchBarValue) {
            this.updateFilteredActivities();

        }

        if (prevState.filteredActivities !== this.state.filteredActivities) {
            this.setupPageCount();
        }

    }

    goTo(path) {
        this.props.history.push(path);
    }

    handleClick = () => {
        if (this.state.edit === true && this.state.add === false) {
            this.setState({ edit: false })
        }
        this.props.setInitialState('activityObj');
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === true && this.state.edit === false) {
                this.setState({
                    name: '',
                })
            }
        });
    };

    onDeleteClick = (data) => {
        this.setState({
            delete: !this.state.delete,
            selection: [data.id],
            data: data
        });
    }

    deleteItem = () => {
        if (this.state.data.id_status !== 4) {
            this.props.deleteActivity(this.state.selection);
            this.setState({ delete: false, data: {} })
        } else {
            toast.error(i18n.t('A locked activity can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    onEditClick = (id) => {
        axios.get(`api/activity/getById?id=` + id)
            .then(res => {
                let data = res.data.data;
                let obj = {
                    id: data.id,
                    id_status: data.id_status,
                    id_radna_operacija: data.id_radna_operacija,
                    indikator_prinosa: data.radna_operacija.indikator_prinosa,
                    datum_pocetka: data.datum_pocetka,
                    datum_zavrsetka: data.datum_zavrsetka,
                    vreme_pocetka: data.vreme_pocetka,
                    vreme_zavrsetka: data.vreme_zavrsetka,
                    table: data.table.map(item => {
                        if (item.tabla.value === undefined) {
                            let obj = item.tabla;
                            obj.value = item.tabla.id;
                            obj.label = item.tabla.naziv;
                            obj.id_tabla = item.tabla.id;
                            obj.sorte = item.sorte;
                            delete obj.id;
                            return obj;
                        }
                        else return item.tabla;
                    }),
                   
                    redovi: data.redovi,
                    materijali: data.materijali,
                    pogonskeMasine: data.pogonskeMasine.map(item => {
                        if (item.pogonska.value === undefined) {
                            let obj = item.pogonska;
                            obj.value = item.pogonska.id;
                            obj.label = item.pogonska.naziv;
                            obj.id_pogonska_masina = item.pogonska.id;
                            obj.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            delete obj.id;
                            return obj;
                        }
                        else {
                            item.pogonska.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            return item.pogonska
                        };
                    }),
                    prikljucneMasine: data.prikljucneMasine.map(item => {
                        if (item.prikljucna.value === undefined) {
                            let obj = item.prikljucna;
                            obj.value = item.prikljucna.id;
                            obj.label = item.prikljucna.naziv;
                            obj.id_prikljucna_masina = item.prikljucna.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.prikljucna;
                    }),
                    komentar: data.komentar,
                    radna_operacija: data.radna_operacija.naziv
                };
                this.setState({ propObj: obj }, () => {
                    this.setState({ new_edit: true });
                })
                
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    onVisibilityClick = (id) => {
        axios.get(`api/activity/getById?id=` + id)
            .then(res => {
                let data = res.data.data;
                let obj = {
                    id: data.id,
                    id_status: data.id_status,
                    id_radna_operacija: data.id_radna_operacija,
                    indikator_prinosa: data.radna_operacija.indikator_prinosa,
                    naziv_radne_operacije: data.radna_operacija.naziv,
                    datum_pocetka: data.datum_pocetka,
                    datum_zavrsetka: data.datum_zavrsetka,
                    vreme_pocetka: data.vreme_pocetka,
                    vreme_zavrsetka: data.vreme_zavrsetka,
                    table: data.table.map(item => {
                        if (item.tabla.value === undefined) {
                            let obj = item.tabla;
                            obj.value = item.tabla.id;
                            obj.label = item.tabla.naziv;
                            obj.id_tabla = item.tabla.id;
                            obj.sorte = item.sorte;
                            delete obj.id;
                            return obj;
                        }
                        else return item.tabla;
                    }),
                    redovi: data.redovi,
                    materijali: data.materijali,
                    pogonskeMasine: data.pogonskeMasine.map(item => {
                        if (item.pogonska.value === undefined) {
                            let obj = item.pogonska;
                            obj.value = item.pogonska.id;
                            obj.label = item.pogonska.naziv;
                            obj.id_pogonska_masina = item.pogonska.id;
                            obj.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            delete obj.id;
                            return obj;
                        }
                        else {
                            item.pogonska.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            return item.pogonska
                        };
                    }),
                    prikljucneMasine: data.prikljucneMasine.map(item => {
                        if (item.prikljucna.value === undefined) {
                            let obj = item.prikljucna;
                            obj.value = item.prikljucna.id;
                            obj.label = item.prikljucna.naziv;
                            obj.id_prikljucna_masina = item.prikljucna.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.prikljucna;
                    }),
                    komentar: data.komentar,
                    radna_operacija: data.radna_operacija.naziv
                };
                this.setState({ propObj: obj }, () => {
                    this.setState({ preview: true });
                })

            })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    onCopyClick = (id) => {
        axios.get(`api/activity/getById?id=` + id)
            .then(res => {
                let data = res.data.data;
                let obj = {
                    id_status: data.id_status,
                    id_radna_operacija: data.id_radna_operacija,
                    indikator_prinosa: data.radna_operacija.indikator_prinosa,
                    datum_pocetka: data.datum_pocetka,
                    datum_zavrsetka: data.datum_zavrsetka,
                    vreme_pocetka: data.vreme_pocetka,
                    vreme_zavrsetka: data.vreme_zavrsetka,
                    table: data.table.map(item => {
                            if (item.tabla.value === undefined) {
                                let obj = item.tabla;
                                obj.value = item.tabla.id;
                                obj.label = item.tabla.naziv;
                                obj.id_tabla = item.tabla.id;
                                delete obj.id;
                                return obj;
                            }
                            else return item.tabla;
                        }),
                
                    redovi: data.redovi,
                    materijali: data.materijali,
                    pogonskeMasine: data.pogonskeMasine.map(item => {
                        if (item.pogonska.value === undefined) {
                            let obj = item.pogonska;
                            obj.value = item.pogonska.id;
                            obj.label = item.pogonska.naziv;
                            obj.id_pogonska_masina = item.pogonska.id;
                            obj.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            delete obj.id;
                            return obj;
                        }
                        else {
                            item.pogonska.id_pogonska_masina_klijent = item.id_pogonska_masina_klijent;
                            return item.pogonska
                        };
                    }),
                    prikljucneMasine: data.prikljucneMasine.map(item => {
                        if (item.prikljucna.value === undefined) {
                            let obj = item.prikljucna;
                            obj.value = item.prikljucna.id;
                            obj.label = item.prikljucna.naziv;
                            obj.id_prikljucna_masina = item.prikljucna.id;
                            delete obj.id;
                            return obj;
                        }
                        else return item.prikljucna;
                    }),
                    komentar: data.komentar,
                    radna_operacija: data.radna_operacija.naziv
                };
                this.setState({ propObj: obj }, () => {
                    this.setState({ new_edit: true, is_copy: true });
                })

            })
            .catch((err) => {
                wentWrong(i18n.t('Something went wrong'))
                console.log(err)
            })
    }

    handleChangeOption = (val) => {
        if (val !== 0) {
            this.setState({ forSortValue: val }, () => {
                axios.get('api/activity/update_sort_client', { params: { status: val } })
                    .then(res => {
                        this.sortByProperty({ val: val });
                    })
                    .catch(() => wentWrong(i18n.t('Something went wrong')))
            });
            this.handleGroupByClose();
        }
    }

    handleGroupByClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleGroupByClose = () => {
        this.setState({ anchorEl: null });
    };
    handleFilterClick = event => {
        this.setState({ anchorElFilter: event.currentTarget });
    };

    handleFilterClose = () => {
        this.setState({ anchorElFilter: null });
    };

    sortByProperty = (val) => {
        let searchText = val;
        let forSort = this.state.activity;
        let sortedData = [];
        if (searchText.val === "by status") {
            let sorted = _.groupBy(forSort, d => d.id_status);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: i18n.language === 'Srpski' ? next[0].status : next[0].status_en,
                    data: next
                })
                return accumulator;
            }, [])
        } else if (searchText.val === "by field") {
            let sorted;
            sorted = _.reduce(forSort, (accumulator, next, index) => {
                _.map(next.table, (ex, indexic, arr) => {
                    if (accumulator.idTable.length === 0) {
                        accumulator.idTable.push(ex.tabla.id);
                        accumulator.data.push({
                            title: ex.tabla.naziv,
                            data: [next]
                        });
                    }
                    else {
                        if (!accumulator.idTable.some((item) => {
                            return item === ex.tabla.id
                        })) {
                            accumulator.idTable.push(ex.tabla.id);
                            accumulator.data.push({
                                title: ex.tabla.naziv,
                                data: [next]
                            });
                        } else {
                            accumulator.data[accumulator.idTable.indexOf(ex.tabla.id)].data.push(next);
                        }
                    }
                });
                return accumulator;
            }, { idTable: [], data: [] });
            sortedData = sorted.data;
        } else if (searchText.val === "by working operation") {
            let sorted = _.groupBy(forSort, d => d.id_radna_operacija);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].radna_operacija,
                    data: next
                })
                return accumulator;
            }, [])
        } else if (searchText.val === "by date") {
            let sorted = _.groupBy(forSort, d => d.datum_pocetka);
            sortedData = _.reduce(sorted, (accumulator, next, index) => {
                accumulator.push({
                    title: next[0].datum_pocetka,
                    data: next
                })
                return accumulator;
            }, [])
        }
        if (sortedData) {
            this.setState({ activityGrouped: sortedData, sorted: true });
        } else {
            // this.setState({ workOrders: this.props.workOrders, sorted: false }, function () {
            //     ToastAndroid.show(I18n.t("error"), ToastAndroid.SHORT);
            // })
        }
    }

    handleSearch = search => {
        this.setState({ searchValue: search }, () => {
            this.props.getActivities(this.state.searchValue, this.state.filters);
        })
    }
    handleChangeFilter = name => selectedOption => {
        let obj = Object.assign({}, this.state.filters);
        obj[name] = name === 'date' ? selectedOption : selectedOption.map((item) => {
            return item.value;
        });
        this.setState({ filters: obj });
    }

    handleCloseFilter = () => {
        this.setState({
            anchorElFilter: null,
        });
    };

    handleClearFilter = () => {
        const { clientProfile } = this.props;
        this.setState({
            filters: {
                date: {
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                },
                id_sezona: clientProfile.id_default_sezona
            }
        }, function () {
            this.props.getActivities(this.state.searchValue, this.state.filters);
        });
    };

    handleOkFilter = () => {
        this.props.getActivities(this.state.searchValue, this.state.filters);
    }


    populateDropdowns = () => {
        let seasons = new Set(this.state.activity && this.state.activity.map(item => item.datum_pocetka.slice(-4)));
        
        // Use an array instead of a Set to store all table names, even duplicates
        let tablesArray = [];
        let uniqueTables = new Set();

        if (this.state.activity) {
            this.state.activity.forEach(item => {
                //console.log(item)
                if (item.table) {
                    //console.log("aaa", item.table.tabla);
                    item.table.forEach(i => {
                        if (i.tabla.id && !uniqueTables.has(i.tabla.id)) {  // Corrected the condition to use '!uniqueTables.has(id)'
                            let tableEntry = {
                                id: i.tabla.id,
                                label: `${i.tabla.naziv} (${Number(i.tabla.povrsina).toFixed(2)} ha)`
                            };
                            tablesArray.push(tableEntry); // Add object with id and formatted label to array
                            uniqueTables.add(i.tabla.id);
                        }
                       
                    });
                }
            });
        }

        // Sort tablesArray by the label (which contains the table name)
        tablesArray.sort((a, b) => a.label.localeCompare(b.label));

        let statuses = new Set(this.state.activity && this.state.activity.map(item => item.status));
        let operations = new Set(this.state.activity && this.state.activity.map(item => item.radna_operacija));
    
        this.setState(
            {
                seasons: [...seasons],
                tables: tablesArray,  // No need to use spread since it's already an array
                statuses: [...statuses],
                operations: [...operations],
                filteredActivities: this.state.activity,
            },
            () => {
                this.updateFiltersFromSessionStorage();
                console.log("this.state.filteredActivities : ", this.state.filteredActivities);
            }
        );
    }
    /*
    populateDropdowns = () => {
        let seasons = new Set(this.state.activity && this.state.activity.map(item => item.datum_pocetka.slice(-4)));
        // let tables = new Set(this.state.activity && this.state.activity.map(item => item.table.map(i => i.tabla.naziv)));
        let setTables = new Set();

        if (this.state.activity) {
            this.state.activity.forEach(item => {
                if (item.table) {
                    item.table.forEach(i => {
                        if (i && i.tabla && i.tabla.naziv) {
                            setTables.add(i.tabla.naziv);
                        }
                    });
                }
            });
        }

        let tables = Array.from(setTables);
        let statuses = new Set(this.state.activity && this.state.activity.map(item => item.status));
        let operations = new Set(this.state.activity && this.state.activity.map(item => item.radna_operacija));

        this.setState(
            {
                seasons: [...seasons],
                tables: [...tables],
                statuses: [...statuses],
                operations: [...operations],
                filteredActivities: this.state.activity,
            },
            () => {
                this.updateFiltersFromSessionStorage();
                console.log("this.state.filteredActivities : ", this.state.filteredActivities)
            }
        );
    }*/

    handleChangeSeason = (e) => {
        this.setState(
            {
                selectedSeason: e.currentTarget.value,
            });
    }
    handleChangeTable = (e) => {
        console.log(e.currentTarget.value)
        this.setState(
            {
                selectedTable: e.currentTarget.value,

            });
    }
    handleChangeStatus = (e) => {
        this.setState(
            {
                selectedStatus: e.currentTarget.value,
            });
    }
    handleChangeOperation = (e) => {
        this.setState(
            {
                selectedOperation: e.currentTarget.value,
            });
    }

    handleDatePickerClick = (e) => {
        this.setState({ anchorDatePicker: e.currentTarget });
    };

    handleDatePickerClose = () => {
        this.setState({ anchorDatePicker: null });
    };
    handleChangeDate = (selectedDate) => {
        this.setState({
            selectedDateRange: selectedDate.range1,
        });
    }

    parseDate = (dateString) => {
        const [day, month, year] = dateString.split('.').map(Number);
        return new Date(year, month - 1, day);
    };

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }
    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('pageNumber', pageNum.selected);
    }
    generateColoredCircle = (status) => {
        let color = "";
        switch (status) {
            case "Zaključan":
                color = "#9b9658";
                break;
            case "U toku":
                color = "#c3d600";
                break;
            case "Otvoren":
                color = "#058457";
                break;
            case "Završen":
                color = "#61a114";
                break;
            case "Planski":
                color = "#58b5e6";
                break;
            default:
                color = "#49c954";
                break;
        }
        let style = {
            width: "13px",
            height: "13px",
            borderRadius: "50%",
            backgroundColor: color,
            border: "1px solid #979797",
            position: "absolute",
            left: "-20px",
            top: "50%",
            transform: "translateY(-50%)",
        }
        return <div style={style}></div>
    }

    updateFilteredActivities = () => {
        this.setState({
            filteredActivities: this.state.activity
                .filter(item => this.state.selectedSeason === "" ? item : item.datum_pocetka.slice(-4) === this.state.selectedSeason)
                .filter(item => {
                    if (this.state.selectedTable == 0) {
                        return true;
                    } else {
                        return item.table.some(i => i.tabla.id == this.state.selectedTable);
                    }
                })
                .filter(item => this.state.selectedStatus === "" ? item : item.status === this.state.selectedStatus)
                .filter(item => this.state.selectedOperation === "" ? item : item.radna_operacija === this.state.selectedOperation)
                .filter((item) => {
                    if (this.state.selectedDateRange.startDate === null) {
                        return item;
                    }
                    if (this.parseDate(item.datum_pocetka).getTime() >= new Date(this.state.selectedDateRange.startDate.toString().slice(4, 15)).getTime() && this.parseDate(item.datum_pocetka).getTime() <= new Date(this.state.selectedDateRange.endDate.toString().slice(4, 15)).getTime()) {
                        return item;
                    }
                })
                .filter(item => item.radna_operacija === "" ? item : item.radna_operacija.toLowerCase().includes(this.state.searchBarValue.toLowerCase())),
            // currentPage: 0,

        }, () => this.setupPageCount());

        const preparedForSaving = JSON.stringify({
            selectedSeason: this.state.selectedSeason,
            selectedTable: this.state.selectedTable,
            selectedStatus: this.state.selectedStatus,
            selectedOperation: this.state.selectedOperation,
            selectedDateRange: this.state.selectedDateRange,
            searchBarValue: this.state.searchBarValue,
        })

        sessionStorage.setItem('preservedFilterValues', preparedForSaving);
    }

    updateFiltersFromSessionStorage = () => {
        const storedFilterValues = sessionStorage.getItem('preservedFilterValues');

        if (storedFilterValues) {
            const parsedFilterValues = JSON.parse(storedFilterValues);

            this.setState({
                selectedSeason: this.state.seasons.includes(parsedFilterValues.selectedSeason) ? parsedFilterValues.selectedSeason : "",
                selectedTable: this.state.tables.includes(parsedFilterValues.selectedTable) ? parsedFilterValues.selectedTable : 0,
                selectedStatus: this.state.statuses.includes(parsedFilterValues.selectedStatus) ? parsedFilterValues.selectedStatus : "",
                selectedOperation: this.state.operations.includes(parsedFilterValues.selectedOperation) ? parsedFilterValues.selectedOperation : "",
                selectedDateRange: {
                    endDate: parsedFilterValues.selectedDateRange.endDate ? new Date(String(parsedFilterValues.selectedDateRange.endDate)) : null,
                    selection: {
                        startDate: parsedFilterValues.selectedDateRange.selection.startDate,
                        endDate: parsedFilterValues.selectedDateRange.selection.endDate,
                        key: parsedFilterValues.selectedDateRange.selection.key,
                    },
                    startDate: parsedFilterValues.selectedDateRange.startDate ? new Date(String(parsedFilterValues.selectedDateRange.startDate)) : null,
                },
                searchBarValue: parsedFilterValues.searchBarValue,
            });

        }
    }

    resetMyFilters = () => {
        this.setState(
            {
                selectedSeason: "",
                selectedTable: 0,
                selectedStatus: "",
                selectedOperation: "",
                selectedDateRange: {
                    startDate: null,
                    endDate: null,
                    selection: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                    }
                },
                searchBarValue: "",
                currentPage: 0,
            })
    }
    // ---------------------pagination-----------------------
    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredActivities.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handleCloseExportWindow = () => {
        this.setState({ exportWindow: false })
    }

    async exportDocument(title, data) {

        let newData = data.map(item => {
            return {
                Sezona: item.sezona,
                Radna_operacija: item.radna_operacija,
                Table: item.table.map(tabla => tabla.tabla.naziv).join('\n'),
                Datum_pocetka: item.datum_pocetka,
                Datum_zavrsetka: item.datum_zavrsetka,
                Status: item.status

            }
        });

        try {
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const ws = XLSX.utils.json_to_sheet(newData);

            ws['!cols'] = [
                { wpx: 100 },
                { wpx: 200 },
                { wpx: 200 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 100 },
                { wpx: 150 },
                { wpx: 80 },
                { wpx: 180 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 },
                { wpx: 120 }
            ]
            ws['!rows'] = [{ hpx: 30 }];

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${title || 'file'}.xlsx`);

        } catch (err) {
            wentWrong(i18n.t('Something went wrong'))
            console.log("BLOB ERROR : ", err)
        }

    };

    exportFunction = () => {
        this.exportDocument(this.state.docTitle, this.state.filteredActivities);
        this.setState({ exportWindow: false });
    }

    render() {

        const { theme, classes } = this.props;
        const { activityGrouped, anchorEl, anchorElFilter, filters } = this.state;
        let activityList = () => {
            return !!activityGrouped ? activityGrouped.map((group, i) => {
                return (
                    <ExpansionPanel key={i}>
                        <ExpansionPanelSummary
                            style={{ background: '#DCDCDC', border: '0' }}
                            expandIcon={<Icons.ExpandMore />}>
                            <Typography variant='h6'>{group.title + ` (${group.data.length})`}</Typography>
                        </ExpansionPanelSummary>
                        <div style={{ maxHeight: '380px', overflowY: 'auto' }}>
                            {
                                !!group.data && group.data.map((data, i) => {
                                    let statusColor = '#58B4E5', statusText = i18n.t('Choose status'), statusIcon = './status/otvoren.svg';
                                    if (data) {
                                        if (data.id_status) {
                                            switch (data.id_status) {
                                                case 1:
                                                    statusColor = '#60BF3C';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Finished')}`;
                                                    statusIcon = './status/zavrsen.svg';
                                                    break;
                                                case 2:
                                                    statusColor = '#f3e741';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('In progress')}`;
                                                    statusIcon = './status/utoku.svg';
                                                    break;
                                                case 3:
                                                    statusColor = '#058457';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Open ')}`;
                                                    statusIcon = './status/otvoren.svg';
                                                    break;
                                                case 4:
                                                    statusColor = '#e40f0f';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Locked')}`;
                                                    statusIcon = './status/zakljucan.svg';
                                                    break;
                                                case 5:
                                                    statusColor = '#2e63c5';
                                                    statusText = `${i18n.t('Status')}: ${i18n.t('Planned')}`;
                                                    statusIcon = './status/planski.svg';
                                                    break;
                                                default:
                                                    statusColor = '#49c954';
                                                    statusText = i18n.t('Choose status');
                                                    statusIcon = './status/zavrsen.svg';
                                                    break;
                                            }
                                        }
                                    }
                                    return (
                                        <ExpansionPanelDetails className={classes.panel} key={i}>
                                            <Typography variant='h6' className="divcontainer">
                                                <Tooltip title={i18n.t(data.status)}>
                                                    <div style={{
                                                        display: 'inline-flex',
                                                        backgroundColor: statusColor,
                                                        padding: '9px',
                                                        borderRadius: '3px',
                                                        borderColor: '#CCCCCC',
                                                        marginRight: '16px',
                                                        fontWeight: '400',
                                                        boxShadow: '1px 1px 2px 0 rgb(0 0 0 / 30%)',
                                                        color: 'white',
                                                        lineHeight: '20px',
                                                        width: 'auto',
                                                        userSelect: 'none',
                                                        justifyContent: 'space-between'
                                                    }}>

                                                        <img src={statusIcon} height="16px" className={iconStyle.icon_filter} style={{ marginTop: '2px' }} />



                                                    </div>
                                                </Tooltip>
                                                <p style={{ display: 'inline', fontWeight: '400' }}>{data.radna_operacija}: </p>
                                                <p style={{ display: 'inline' }}>{data.datum_pocetka}</p>
                                            </Typography>
                                            <div>
                                                <Tooltip title={i18n.t("Preview")}>
                                                    <IconButton aria-label="preview"
                                                        color="secondary"
                                                        onClick={() => this.onVisibilityClick(data.id)}>
                                                        <Icons.Visibility fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={i18n.t("Edit")}>
                                                    <IconButton aria-label="edit"
                                                        color="secondary"
                                                        onClick={() => this.onEditClick(data.id)}>
                                                        <Icons.Edit fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={i18n.t("Copy")}>
                                                    <IconButton aria-label="copy"
                                                        color="secondary"
                                                        onClick={() => this.onCopyClick(data.id)}>
                                                        <Icons.FileCopy fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={i18n.t("Delete")}>
                                                    <IconButton aria-label="Delete"
                                                        className={classes.cssRoot}
                                                        style={{ marginRight: '10px' }}
                                                        onClick={() => this.onDeleteClick(data)}>
                                                        <Icons.Delete fontSize="large" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </ExpansionPanelDetails>)
                                })
                            }
                        </div>
                    </ExpansionPanel>
                )
            }) : null;
        }
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {/* <div className={classes.page}> */}
                {/* <Card className={classes.card}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={
                            <div className={classes.row}>
                                <div style={{ marginRight: 20 }}>
                                    <Typography className={classes.typography} variant='h6'>{i18n.t('Activity')}</Typography>
                                    <Typography className={classes.typography} variant='h6'>{i18n.t(this.state.forSortValue)}</Typography>
                                </div>
                                <Tooltip title={i18n.t("Group By")}>
                                    <IconButton aria-label="groupBy"
                                        aria-owns={anchorEl ? 'groupBy-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.handleGroupByClick}>
                                        <span className="icon-StrelicaDole1 menuIcon2" />
                                    </IconButton>
                                </Tooltip>
                                <SearchBar
                                    className={classes.search}
                                    placeholder={i18n.t('Search')}
                                    cancelOnEscape={true}
                                    value={this.state.searchValue}
                                    onChange={(searchValue) => !searchValue && this.handleSearch('')}
                                    onRequestSearch={(searchValue) => this.handleSearch(searchValue)}
                                    onCancelSearch={() => this.handleSearch('')}
                                />
                                <Menu
                                    id="groupBy-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleGroupByClose}
                                >
                                    <MenuItem onClick={() => this.handleChangeOption('by field')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Field')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by status')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Status')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by working operation')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Working operation')}</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => this.handleChangeOption('by date')} >
                                        <Typography style={{ fontSize: '14px' }}>{i18n.t('Date')}</Typography>
                                    </MenuItem>
                                </Menu>

                            </div>
                        }
                        action={
                            <div className="filter-add-rn">
                                <Tooltip title={i18n.t("Filter")}>
                                    <IconButton aria-label="filter"
                                        aria-owns={anchorElFilter ? 'filter-menu' : null}
                                        aria-haspopup="true"
                                        onClick={this.handleFilterClick}>
                                        <span className="icon-filter menuIcon2" />
                                    </IconButton>
                                </Tooltip>

                                <Menu
                                    id="filter-menu"
                                    anchorEl={anchorElFilter}
                                    open={Boolean(anchorElFilter)}
                                    onClose={this.handleFilterClose}
                                >
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Status')}
                                        maxMenuHeight={150}
                                        menuPlacement='auto'
                                        value={this.state.statusDropdown && this.state.statusDropdown.filter(function (element) {
                                            return filters.id_status && filters.id_status.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_status')}
                                        options={this.state.statusDropdown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                    />
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Field')}
                                        maxMenuHeight={150}
                                        menuPlacement='auto'
                                        value={this.state.fieldDropDown && this.state.fieldDropDown.filter(function (element) {
                                            return filters.id_tabla && filters.id_tabla.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_tabla')}
                                        options={this.state.fieldDropDown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                    />
                                    <ReactSelect
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isClearable={true}
                                        label={i18n.t('Working operation')}
                                        maxMenuHeight={150}
                                        value={this.state.woDropdown && this.state.woDropdown.filter(function (element) {
                                            return filters.id_radna_operacija && filters.id_radna_operacija.some((item) => { return item === element.value });
                                        })}
                                        onChange={this.handleChangeFilter('id_radna_operacija')}
                                        options={this.state.woDropdown}
                                        className="multi-select-in-filter-wo"
                                        classNamePrefix="select"
                                        placeholder={i18n.t('Select')}
                                    />
                                    {/* DATE ----------------------------------------- */}
                {/* <InputLabel className={classes.filterLabel}>{i18n.t('Date')}:</InputLabel>
                                     <DateRange
                                        ranges={[filters.date.selection]}
                                        className={'centered'}
                                        onChange={this.handleChangeFilter('date')}
                                        preview={{ color: theme.palette.primary.main }}
                                    />
                                    <div style={{ padding: '20px', paddingTop: '100px', display: 'flex', justifyContent: 'space-between' }}>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleOkFilter}>{i18n.t('Ok')}</Button>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleCloseFilter}>{i18n.t('Close')}</Button>
                                        <Button color='secondary' variant="contained" style={{ margin: '5px', width: '150px' }} onClick={this.handleClearFilter}>{i18n.t('Clear')}</Button>

                                    </div>
                                </Menu>
                                {/* <Tooltip title={i18n.t("Search")}>  // this section was commented out
                                    <IconButton aria-label="search">
                                        <span className="icon-search menuIcon2" />
                                    </IconButton>
                        </Tooltip>*/}
                {/* <Button
                                    variant="fab"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.actionButton}
                                    style={{ marginRight: '16px', marginBottom: '3px', marginLeft: '4px' }}
                                    // onClick={this.handleClick}
                                    onClick={() => this.goTo('/new_activity')}
                                >
                                    <AddIcon />
                                </Button>
                                {i18n.t('Add activity')}
                            </div>
                        } */}
                {/* />
                    <CardContent>
                        {activityList()}
                    </CardContent>
                </Card> */}
                {/* </div > */}

                {this.state.exportWindow && <Export
                    export={this.state.exportWindow}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    // handleClose={() => this.setState({ exportWindow: !this.state.exportWindow })}
                    handleClose={this.handleCloseExportWindow}
                    exportDoc={this.exportFunction} />}

                {this.state.add && <ActivitiesMain add={this.state.add} edit={this.state.edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.new_edit && <Redirect from="/activities" to={{ pathname: "/new_activity", state: { propObj: this.state.propObj, edit: true, is_copy: this.state.is_copy } }} />}
                {this.state.preview && <Redirect from="/activities" to={{ pathname: "/preview", state: { propObj: this.state.propObj, edit: true } }} />}
                {/* -------------------------------------------------------------------------------------------- */}
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by operation')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        // onChange={(searchValue) => !searchValue && this.handleSearchBar('')}
                        // onRequestSearch={(searchValue) => this.handleSearchBar(searchValue)}
                        // onCancelSearch={() => this.handleSearchBar('')}
                        onChange={(searchValue) => this.handleSearchBar(searchValue)}
                        onCancelSearch={() => this.handleSearchBar('')}
                    />

                    {/* <Button
                        variant="fab"
                        color="secondary"
                        aria-label="Export"
                        className={classes.addNewBtn}
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow }, () => {
                            console.log("this.state.exportWindow : ", this.state.exportWindow)
                        })}
                    >
                        {i18n.t('Export')}
                    </Button> */}

                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.addNewBtn}
                        onClick={() => this.goTo('/new_activity')}
                    >
                        <AddIcon />
                        {i18n.t('Add activity')}
                    </Button>

                </div>
                <div className={classes.filtersSection}>
                    <select className={classes.dropDowns} value={this.state.selectedStatus} onChange={this.handleChangeStatus} name="status" id="status">
                        <option value="">{i18n.t('Status')}</option>
                        {this.state.statuses && this.state.statuses.map((status, i) => (
                            <option key={i} value={status}>{status}</option>
                        ))}
                    </select>
                    <Tooltip title={i18n.t('Date')}>
                        <IconButton aria-label="filter"
                            aria-owns={this.state.anchorDatePicker ? 'datePicker' : null}
                            aria-haspopup="true"
                            className={classes.dateSelectBtn}
                            onClick={this.handleDatePickerClick}>
                            <CalendarMonthIcon />
                            <span className={classes.dateSelectText}>{i18n.t('Date')}</span>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id="datePicker"
                        anchorEl={this.state.anchorDatePicker}
                        open={Boolean(this.state.anchorDatePicker)}
                        onClose={this.handleDatePickerClose}
                    >
                        {/* <InputLabel className={classes.filterLabel}> */}
                        {/* {i18n.t('Date')}: */}
                        {/* </InputLabel> */}
                        <div className={classes.calendarTitle}>{i18n.t('Date')}:</div>
                        <DateRange
                            ranges={[this.state.selectedDateRange]}
                            // moveRangeOnFirstSelection={false}
                            className={classes.calendar}
                            onChange={this.handleChangeDate}
                            preview={{ color: theme.palette.primary.main }}
                        />
                    </Menu>
                    {/* Unapredjujemo ovo ne pokazuje hektare */}
                    
                    <select className={classes.dropDowns} value={this.state.selectedTable} onChange={this.handleChangeTable} name="table" id="table">
                        <option value="">{i18n.t('Fields')}</option>
                        {this.state.tables && this.state.tables.map((table) => (
                            <option key={table.id} value={table.id}>
                                {table.label}
                            </option>
                        ))}
                    </select>

                    <select className={classes.dropDowns} value={this.state.selectedOperation} onChange={this.handleChangeOperation} name="operations" id="operations">
                        <option value="">{i18n.t('Working operations')}</option>
                        {this.state.operations && this.state.operations.map((operation, i) => (
                            <option key={i} value={operation}>{operation}</option>
                        ))}
                    </select>
                    <button className={classes.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>

                    <Button
                        onClick={() => this.setState({ exportWindow: !this.state.exportWindow })}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className={classes.exportButton}
                    // style={{ marginLeft: 'auto !important' }}

                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>
                    <div className={classes.tableHeader}>
                        <div style={{ width: '128px' }} />
                        <div className={classes.tableHeaderTitle} >{i18n.t("All activities")}</div>
                        <div className={classes.selectSeasonSection}>
                            <CalendarMonthIcon
                                style={{ color: 'white' }}
                            />
                            <label style={{ paddingTop: '5px' }} htmlFor="sezone">{i18n.t("Season")}:</label>
                            <select value={this.state.selectedSeason} onChange={this.handleChangeSeason} name="sezone" id="sezone">
                                <option value="">{i18n.t("All")}</option>
                                {this.state.seasons && this.state.seasons.map((sezona, i) => (
                                    <option key={i} value={sezona}>{sezona}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                        {/* <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Number")}</div> */}
                        <div style={{ width: '100px', marginRight: "20px" }}>{i18n.t("Start date")}</div>
                        <div style={{ width: '250px', marginRight: "20px" }}>{i18n.t("Working operation")}</div>
                        <div style={{ width: '200px', marginRight: "20px" }}>{i18n.t("Field")}</div>
                        <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Status")}</div>
                        <div style={{
                            width: '237px',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Preview")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Edit")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Copy")}</div>
                            <div style={{ width: '60px', marginRight: "20px" }}>{i18n.t("Delete")}</div>
                        </div>
                    </div>
                    {this.state.filteredActivities && this.state.filteredActivities.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2' }}>
                            {/* <div style={{ width: '60px', marginRight: "20px", color: '#9b9658', fontSize: '14px', fontWeight: 'bold' }}>{item.id}</div> */}
                            <div style={{ width: '100px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.datum_pocetka}</div>
                            <div style={{ width: '250px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.radna_operacija}</div>
                            <div style={{ width: '200px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.table.slice(0, 3).map((i, index) => {

                                if (item.table.length > 3 && index === 2) {
                                    return <Tooltip
                                        key={index}
                                        placement="right"
                                        title={
                                            <div style={{ fontSize: '14px', padding: '10px' }}>
                                                <div style={{ borderBottom: '1px solid white', marginBottom: '10px', paddingBottom: '10px' }}>{i18n.t('Fields')}:</div>
                                                {item.table.map((tabla, index) => {
                                                    return <div key={index}>{tabla.tabla.naziv}</div>
                                                })}
                                            </div>
                                        }>
                                        <div style={{ color: 'rgb(88, 181, 230)' }}>
                                            ...prikaži više
                                        </div>
                                    </Tooltip>
                                } else {
                                    return <div key={index}>{i.tabla.naziv}</div>
                                }



                            })}</div>
                            <div style={{ width: '60px', marginRight: "20px", fontSize: '14px', color: '#333333', position: 'relative' }}>
                                {this.generateColoredCircle(item.status)}
                                {item.status}
                            </div>
                            <div>
                                <Tooltip title={i18n.t("Preview")}>
                                    <IconButton aria-label="preview"
                                        style={{ transform: 'translateX(-11px)' }}
                                        // color="secondary"
                                        onClick={() => this.onVisibilityClick(item.id)}>
                                        <Icons.Visibility fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n.t("Edit")}>
                                    <IconButton aria-label="edit"
                                        // color="secondary"
                                        style={{ transform: 'translateX(-5px)' }}
                                        onClick={() => this.onEditClick(item.id)}
                                        disabled={item.id_status === STATUS_CLOSED}>
                                        <Icons.Edit fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n.t("Copy")}>
                                    <IconButton aria-label="copy"
                                        // color="secondary"
                                        onClick={() => this.onCopyClick(item.id)}>
                                        <Icons.FileCopy fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        // className={classes.cssRoot}
                                        onClick={() => this.onDeleteClick(item)}>
                                        <Icons.DeleteOutline fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    ))}
                    <ReactPaginate
                        pageCount={this.state.pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={classes.paginationContainer}
                        activeClassName={classes.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        activity: state.activityReducer.activity,
        activityFetching: state.activityReducer.activityFetching,
        activityPages: state.activityReducer.activityPages,
        activityObj: state.activityReducer.activityObj,
        activityPostingSuccess: state.activityReducer.activityPostingSuccess,
        woDropdown: state.activityReducer.woDropdown,
        statusDropdown: state.activityReducer.statusDropdown,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        fieldFetchingDropDown: state.fieldReducer.fieldFetchingDropDown,
        clientProfile: state.appReducer.clientProfile,
        deleteActivitySuccess: state.activityReducer.deleteActivitySuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getActivities: (search, filter) => dispatch(getActivities(search, filter)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getById: (id, opt) => dispatch(getById(id, opt)),
        getWODropdown: () => dispatch(getWODropdown()),
        deleteActivity: (selection) => dispatch(deleteActivity(selection)),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        getStatusDropdown: () => dispatch(getStatusDropdown()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityContainer));